import React from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

const TermsOfUse = () => {
  return (
    <>
      <Header />
      <div className="bg-gray-100 min-h-screen p-8 w-full">
        <div className="max-w-6xl mx-auto bg-white p-8 shadow-md rounded-lg w-[70%]">
          <h1 className="flex w-full justify-center p-4 text-xl font-playfair py-2 font-bold text-quoteText">
            TERMS OF USE
          </h1>

          {/* Introduction Section */}
          <section className="mb-8">
            <p className="text-gray-700 mb-4">
              These Terms of Use (the “Terms of Use”) govern your access to and
              use of Pennsylvania Legislative Services, LLC (“Company”)
              legislative data and intelligence service covering the legislature
              of the Commonwealth of Pennsylvania (the “Service”) and access to
              the Service on the Company’s website, its subdomains, and all of
              the website and Internet properties owned or operated by Company
              (the “Site”). Use of the Service is a subscription product of the
              Company and while subscriptions are at an organization level, each
              individual within the organization who logs into the Site is
              required to have their own access code consisting of an individual
              ID and password. As an authorized user, your use of the Service is
              subject to certain terms and conditions, as follows:
            </p>
          </section>

          {/* Permitted Uses Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Permitted Uses
            </h2>
            <p className="text-gray-700 mb-4">
              The Service contains copyrighted information and statistics either
              owned or developed by the Company’s staff, public domain
              information such as the text of bills, and subscriber-contributed
              information the combination of which constitutes a copyrighted
              publication. As an authorized user, you are granted a license to
              use the information for the following purposes, subject to the
              restrictions set forth below under Prohibited Uses:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>
                Researching and tracking legislation for yourself, your
                organization and a limited number of clients or members, as
                agreed upon with the Company.
              </li>
              <li>
                Generating reports for your own use and the use of your
                organization and its clients or members.
              </li>
              <li>
                Generating calendars for your own use and the use of your
                organization.
              </li>
              <li>
                Creating and storing profiles, positions, analyses, and other
                data about the bills you track, and generating reports based on
                them.
              </li>
              <li>
                Forwarding any information to authorized users within your
                organization, not including audio material.
              </li>
            </ul>

            <p className="text-gray-700 mb-4">
              <br />
              Subject to the restrictions set forth below under Prohibited Uses,
              you are permitted to generate tracking reports that you share with
              your clients or members in print or electronic form. As generated
              from the Site, the reports will contain a copyright notice, which
              is not to be removed before sharing the reports. If you wish to
              post such reports on your own web site, they must be maintained in
              a password-protected area of your site subject to an approved
              Company fee arrangement, in the Company’s sole discretion.
            </p>
            <p className="text-gray-700 mb-4">
              You may use your own subscriber-contributed information in any way
              you wish. However, reports that contain a combination of your
              user-generated information and Company information is subject to
              these Terms of Use, in its, and their, entirety.
            </p>
          </section>

          {/* Prohibited Uses Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Prohibited Uses
            </h2>
            <p className="text-gray-700 mb-4">
              You are not permitted to share your username and password for
              access to the Site. Each authorized user within an organization
              must obtain his or her own username and password. Sharing a
              username and password within your organization, with a client, or
              with any other third party is expressly prohibited. If the Company
              believes that unauthorized use of your username and password may
              be occurring, the Company, in its sole discretion, may (i)
              terminate your access to the Service, (ii) terminate your
              subscription, (iii) modify or suspend your access to the Service,
              or (iv) require you to change login identification codes or
              passwords.
            </p>
            <p className="text-gray-700 mb-4">
              Forwarding or other copying or sharing of reports, calendars,
              email alerts, or other information generated by, provided by, or
              received from the Services or the Company to persons outside your
              organization, or to unauthorized persons within your organization,
              is a violation of these Terms of Use.
            </p>
            <p className="text-gray-700 mb-4">
              If you forward, copy or share reports, calendars, email alerts, or
              other information generated by, provided by, or received from the
              Service or the Company to your client, your client(s) is/are
              expressly prohibited from forwarding to third-parties or posting
              on their respective website such reports, calendars, email alerts,
              or other information. In the event of any such unlawful and
              non-permitted dissemination, the Company will pursue all remedies
              available to it against both you and your client.
            </p>
            <p className="text-gray-700 mb-4">
              You are not permitted to resell the Service, share the Service, or
              give, sell, rent, or otherwise distribute the Service or its
              content to other persons or organizations. You are not permitted
              to assign, sublicense, or transfer your subscription to a third
              party.
            </p>
            <p className="text-gray-700 mb-4">
              You are not permitted to ‘frame’ or ‘mirror’ any Company content
              on any other server or Internet-based device.
            </p>
            <p className="text-gray-700 mb-4">
              You may not use the Site or Services to post, transmit or
              otherwise make available using the Service any information or
              material that is or may be: (i) false, libelous, defamatory,
              fraudulent or otherwise unlawful or tortious; (ii) threatening,
              harassing, degrading, hateful or intimidating, or that otherwise
              fail to respect the rights and dignity of others; (iii) obscene,
              indecent, pornographic or otherwise objectionable; (iv) protected
              by any third-party copyright, trademark, design rights, trade
              secret rights, right of publicity or privacy, or any other
              proprietary right, without the express prior written consent of
              the applicable owner; (v) subject to confidentiality obligation
              without the consent of the disclosing Party; (vi) advertising,
              spam, an offer to sell or buy any goods or services, a “chain
              letter” or any other form of solicitation or otherwise duplicative
              or unsolicited messages in violation of applicable laws.
            </p>
          </section>

          {/* Copyrights Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Copyrights
            </h2>
            <p className="text-gray-700 mb-4">
              The Company, including the software that operates the Site and all
              of the content of the Site, including all of the text, images, and
              other materials posted on the Site, all pages and screens, and all
              related metadata, along with emails and other electronic alerts
              from the Company (collectively, the “Content”) are protected under
              U. S. Copyright and other intellectual property laws. All rights
              in the Site and the Content are owned by the Company, or its
              licensors, each of whom reserves all of its rights. The entire
              Content of the Site is copyrighted as a collective work under U.S.
              copyright law, and the Company, reserves all rights in the
              copyright in the selection, coordination, arrangement and
              enhancement of the Content of the Site. All of the Company’s or
              its licensors’ intellectual property rights regarding or related
              to the Content will be enforced to the maximum extent allowable
              under the laws.
            </p>
            <p className="text-gray-700 mb-4">
              As outlined under ‘Permitted Uses,’ you may download selected
              Content displayed on the Site for your own, your clients’ or your
              organization’s internal business use, provided (a) you do not
              remove or modify any copyright notices; and (b) you comply with
              all copyright and other intellectual property laws. This limited,
              revocable, nontransferable privilege to use the Content in no way
              constitutes a transfer of any right, title or interest in the
              Content you download. You, your clients, your organization, and/or
              members of your organization shall not, under any circumstances:
              (1) use or exploit the Content for any commercial purpose (except
              for your, your clients’, your organization’s, and/or members of
              your organization’s, internal business use); (2) decompile,
              reverse engineer, or disassemble any part of the Site, including,
              but not limited to, its Content; or (3) upload, post or use any
              Content on any other Web site or any networked computer
              environment including, but not limited to, your, your clients’,
              your organization’s, and/or members of your organization’s, Web
              sites or networked computer environment.
            </p>
            <p className="text-gray-700 mb-4">
              Certain areas of the Site may allow you to post text and other
              materials to the Site (“Your Content”), including interaction with
              other parties regarding the same. As a condition of submitting any
              Content or other materials to the Sites or Services, you grant
              Company a royalty free, perpetual, irrevocable, worldwide,
              nonexclusive, transferable, and sublicensable license to use,
              reproduce, copy, adapt, modify, merge, distribute, publicly
              display, create derivative works from, incorporate such Your
              Content to provide the Site and Services.
            </p>
          </section>

          {/* Password Protected Areas Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Password Protected Areas of the Site
            </h2>
            <p className="text-gray-700 mb-4">
              Certain areas of the Site are password protected. You are
              responsible for maintaining the confidentiality of your username
              and password. The Company has the right to assume that anyone
              accessing the password protected areas of the Site using a
              password assigned to you has the right to do so. You and your
              organization will be solely responsible for the activities of
              anyone accessing the Site using a password assigned to you, even
              if the individual is not, in fact, authorized by you. You agree
              that you will immediately notify the Company of any known or
              suspected unauthorized use of your password or any other breach of
              security and will contact the Company to have your password
              changed if you believe your password may have been compromised or
              used without authorization.
            </p>
          </section>

          {/* Privacy Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Privacy
            </h2>
            <p className="text-gray-700 mb-4">
              The Company will treat your subscriber-contributed information as
              confidential and will not release, exchange, or sell any of your
              information to a third party without your express permission,
              unless required by law or court or governmental order.
            </p>
            <p className="text-gray-700 mb-4">
              Many features work in concert with your email address and/or SMS
              messaging to your mobile phone or other mobile device. Information
              about the addresses of these devices is necessary for full
              functionality. White-listing the Company server addresses may be
              necessary to obtain email messages reliably; if so, it is your
              responsibility to accomplish the necessary white listing. The
              Company will not release, exchange, or sell such address
              information to a third party, other than a licensor of the
              enabling software, unless required to do so by law or court or
              governmental order. The Company makes use of cookies, pop-ups,
              JavaScript, and other tools which are treated as privacy concerns
              by some virus protection software. Enabling the use of these tools
              in your browser is necessary for full functionality.
            </p>
          </section>

          {/* Your Risks Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Your Risks
            </h2>
            <p className="text-gray-700 mb-4">
              Use of the Service and any reliance by you upon any Content,
              including any action taken by you because of such use or reliance,
              is at your sole risk. Neither the Company, nor any of its
              underlying suppliers, service providers, business partners,
              licensors, or officers, directors, stockholders, employees or
              contractors is responsible or liable for, or makes any
              representations or warranties as to: (i) the timeliness, accuracy,
              reliability, completeness, legality, or decency of the Service or
              any Content; (ii) any inaccuracy, omission, error or delay in the
              Service or any Content; (iii) nonperformance of or interruption in
              the Service or any Content due to: (A) any act or omission by any
              disseminating party, (B) any force majeure (i.e., flood; riot;
              labor dispute; accident; action of government; communications,
              transmissions or power failure; equipment, systems or software
              malfunctions) or any other cause beyond the control of any
              disseminating party or (C) outages, transmission quality or
              malfunctions of telephone circuits or computer systems including
              but not limited to any defects or failures with respect to your
              software, computer systems or Internet access provider;(iv) the
              quality of the Service or any content (including the results to be
              obtained from use of them); or (v) any loss resulting from,
              arising out of or related to your access and/or use of or
              interaction with the Service or the Content.
            </p>
          </section>

          {/* Disclaimer of Warranty Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Disclaimer of Warranty
            </h2>
            <p className="text-gray-700 mb-4">
              THE SITE AND SERVICES ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE”
              BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND. TO THE
              FULLEST EXTENT PERMITTED BY LAW, THE COMPANY, DISCLAIMS AND ALL
              REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, OR
              STATUTORY, WITH RESPECT TO THE SITE OR SERVICES, INCLUDING ITS
              CONTENT, AND ANY PRODUCTS AND SERVICES MADE AVAILABLE THROUGH THE
              SITE AND SERVICES. THE COMPANY DISCLAIMS ALL REPRESENTATIONS AND
              WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, (A) OF TITLE,
              NONINFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
              PURPOSE; (B) ARISING FROM COURSE OF DEALING OR COURSE OF
              PERFORMANCE; (C) RELATING TO THE SECURITY OF THE SITE; (D) THAT
              THE CONTENT OR OTHER INFORMATION ON THE SITE OR SERVICES IS
              TIMELY, CURRENT, ACCURATE, COMPLETE, RELEVANT OR RELIABLE; (E)
              THAT THE SITE WILL OPERATE WITHOUT INTERRUPTION OR ERROR, AND (F)
              REGARDING THE RESULTS TO BE OBTAINED FROM USING THE SITE,
              INCLUDING ITS CONTENT. YOU AND YOUR ORGANIZATION USE THE SITE AND
              SERVICES, INCLUDING ITS CONTENT, AT YOUR OWN RISK.
            </p>
          </section>

          {/* Limitations of Liability Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Limitations of Liability
            </h2>
            <p className="text-gray-700 mb-4">
              UNDER NO CIRCUMSTANCES SHALL THE COMPANY, OR ANY OF ITS EMPLOYEES,
              CONTRACTORS, DIRECTORS, OFFICERS, LICENSORS OR SERVICE PROVIDERS
              HAVE ANY RESPONSIBILITY OF ANY KIND OR BE LIABLE TO YOU, YOUR
              ORGANIZATION, OR TO ANY THIRD PARTY FOR ANY LOSSES, LIABILITIES,
              DAMAGES, COSTS OR EXPENSES ARISING OUT OF OR IN CONNECTION WITH
              THE SITE OR SERVICES, INCLUDING (a) THE USE OF OR INABILITY TO USE
              THE SITE OR SERVICES (INCLUDING ANY FAILURE OF PERFORMANCE, ERROR,
              OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR
              TRANSMISSION, COMPUTER VIRUS, WORM OR OTHER MALICIOUS COMPUTER
              CODE, OR LINE OR SYSTEM FAILURE), OR (b) ANY CONTENT PROVIDED
              THROUGH THE SITE OR SERVICES. THIS IS A COMPREHENSIVE LIMITATION
              OF LIABILITY THAT APPLIES TO ALL LOSSES, INJURIES, CLAIMS,
              LIABILITIES, DAMAGES, COSTS AND EXPENSES OF ANY KIND WHATSOEVER
              (WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, CONSEQUENTIAL,
              INCIDENTAL, EXEMPLARY OR OTHERWISE, INCLUDING WITHOUT LIMITATION,
              LOSS OF DATA, INCOME, SAVINGS OR PROFITS); WHETHER THE CLAIM IS IN
              CONTRACT, TORT (INCLUDING NEGLIGENCE), OR STRICT LIABILITY OR
              BASED ON ANY OTHER LEGAL THEORY; EVEN IF AN AUTHORIZED
              REPRESENTATIVE OF THE COMPANY HAS BEEN ADVISED OF OR SHOULD HAVE
              KNOWN OF THE POSSIBILITY OF SUCH DAMAGES; AND WITHOUT REGARD TO
              THE EFFECTIVENESS OF OTHER REMEDIES.
            </p>
            <p className="text-gray-700 mb-4">
              Your sole and exclusive remedy for any failure or nonperformance
              of the Site, Service or any Content (including any associated
              software) shall be for the Company to use commercially reasonable
              efforts to adjust or repair the Service or correct the Content.
              You may not terminate the subscription; provided, however, if you
              attempt to terminate the subscription or cease utilizing the
              Service, you shall not be entitled to any refund of your
              subscription fee.
            </p>
            <p className="text-gray-700 mb-4">
              In the event the preceding limitation is limited by applicable
              law, then the following shall apply to Company:
            </p>
            <p className="text-gray-700 mb-4">
              NEITHER LICENSOR NOR ITS LICENSORS OR SUPPLIERS SHALL BE LIABLE
              FOR CUMULATIVE, AGGREGATE DAMAGES GREATER THAN AN AMOUNT EQUAL TO
              THE AMOUNTS ACTUALLY PAID BY A USER TO COMPANY FOR THE SERVICES
              DURING THE PERIOD OF SIX (6) MONTHS PRECEDING THE DATE ON WHICH
              THE CLAIM FIRST ACCRUED.
            </p>
          </section>

          {/* Term of Subscription Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Term of Subscription
            </h2>
            <p className="text-gray-700 mb-4">
              The Service is either a twelve (12) – month or twenty-four (24) –
              month subscription, as applicable, commencing on your subscription
              date. These Terms of Use will continue for the entire period of
              your subscription, unless amended and/or modified as set forth
              below. Access to the prior years’ legislative activity is
              available to current, paid-in-full subscribers only.
            </p>
          </section>

          {/* Payment Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Payment
            </h2>
            <p className="text-gray-700 mb-4">
              Payment for all subscriptions is due by the date reflected on your
              invoice based upon your selected annual, semi-annual or quarterly
              payment schedule. Any unpaid balance shall accrue interest at the
              rate of ten percent (10%) compounded monthly until all accrued and
              unpaid interest and the subscription is paid in full. In the event
              the Company must initiate legal and/or collection action against
              you, in addition to all accrued and unpaid interest and the
              subscription fee due and outstanding, the Company, you shall be
              responsible for all Expenses (as defined below) associated with
              all collections and collection activity.
            </p>
          </section>

          {/* Remedies Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Remedies
            </h2>
            <p className="text-gray-700 mb-4">
              The parties hereby agree that irreparable damage, for which
              monetary damages, even if available, would not be an adequate
              remedy, would occur in the event that any of the provisions of
              Terms of Use were not performed or adhered to, or were threatened
              to be not performed or adhered to, in accordance with their
              specific terms or were otherwise materially breached. It is
              accordingly agreed that, in addition to any other remedy that may
              be available to it, including monetary damages, the Company shall
              be entitled to an injunction or injunctions to prevent material
              breaches of these Terms of Use. The parties hereto further agree
              that the Company shall not be required to obtain, furnish or post
              any bond or similar instrument in connection with or as a
              condition to obtaining any remedy referred to in this Remedies
              Section and you hereby waive any objection to the imposition of
              such relief or any right it may have to require the obtaining,
              furnishing or posting of any such bond or similar instrument.
            </p>
          </section>

          {/* Expenses  Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Expenses
            </h2>
            <p className="text-gray-700 mb-4">
              In the event the Company pursues any claims or remedies available
              to it to enforce these Terms of Use, at law, in equity, or both,
              you will be responsible for all of the Company’s expenses
              associated with, arising from, or incurred in connection with, in
              whole or in part, including, without limitation, reasonable
              attorneys’ fees, out-of-pocket expenses, and other reasonable
              costs and expenses incurred in investigating, preparing,
              prosecuting, or defending any pending or threatened lawsuit,
              action, or proceeding (collectively “Expenses”)
            </p>
          </section>

          {/* Waiver Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Waiver
            </h2>
            <p className="text-gray-700 mb-4">
              The Company’s respective rights and remedies under these Terms of
              Use are cumulative and not alternative. Neither the failure nor
              any delay by the Company in exercising any right, power or
              privilege under these Terms of Use will operate as a waiver of
              such right, power or privilege, and no single or partial exercise
              of any such right, power or privilege will preclude any other or
              further exercise of such right, power or privilege or the exercise
              of any other right, power or privilege. No waiver will be
              effective unless it is in writing and signed by an authorized
              representative of the Company. No waiver given will be applicable
              except in the specific instance for which it was given. No notice
              to or demand on you will constitute a waiver of any obligation of
              you or the right of the Company giving such notice or demand to
              take further action without notice or demand as provided in these
              Terms of Use.
            </p>
          </section>

          {/* Government  Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Government{' '}
            </h2>
            <p className="text-gray-700 mb-4">
              For U.S. Government procurement, all Services that constitute or
              include software are deemed to be commercial computer software as
              defined in FAR 12.212 and DFARS 227.7202, as applicable, and any
              successor regulations. Any use, modification, reproduction
              release, performance, display or disclosure of the software by the
              U.S. Government will be solely in accordance with the license
              rights, restrictions and other terms set forth in these Terms.
            </p>
          </section>

          {/* You and Your Organization Agree to be Bound by These Terms of Use  Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              You and Your Organization Agree to be Bound by These Terms of Use
            </h2>
            <p className="text-gray-700 mb-4">
              As used in these Terms of Use, the word ‘you’ refers to you
              individually and, if you are using the Site on behalf of your
              company or organization, your company or organization.
            </p>
            <p className="text-gray-700 mb-4">
              These Terms of Use govern your use and your organization’s use of
              the Company both as a paid subscriber or as a free-trial user.
              These Terms of Use constitute a legally binding contract between
              us and you and, if you are accessing the Site on behalf of a
              business or government organization (‘your organization’), between
              us and your organization.
            </p>
            <p className="text-gray-700 mb-4">
              By accessing or using the Site for your own personal use, you are
              agreeing to be legally bound by these Terms of Use. By accessing
              or using the Site on behalf of your organization, you are
              agreeing, on behalf of yourself and your organization, that you
              and your organization will be legally bound by these Terms of Use.
            </p>
          </section>

          {/* Terms of Use May Change From Time to Time Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Terms of Use May Change From Time to Time
            </h2>
            <p className="text-gray-700 mb-4">
              We are continually adding features and functionality to the Site.
              Because of these changes, changes in the law, or changes in
              technology, we may need to revise these Terms of Use from time to
              time. Accordingly, we reserve the right to update or modify these
              Terms of Use, at any time and without prior notice, by posting the
              revised version of these Terms of Use on the Site. These changes
              will be effective as of the date we post the revised version on
              the Site. Your use of the Site following any such change
              constitutes your agreement (and, if you are using the Site on
              behalf of your organization, your organization’s agreement) to be
              bound by the revised Terms of Use.
            </p>
            <p className="text-gray-700 mb-4">
              To alert you to changes in these Terms of Use, you will be
              presented with a notification when you log in to the site after a
              change. In addition, we will provide a notice at the top of these
              Terms of Use for at least 30 days after the new effective date and
              highlight the changes so that you can locate them easily (unless
              the change is a minor change, such as a change in our contact
              information, or a non-substantive change, such as the correction
              of a typographical error).
            </p>
            <p className="text-gray-700 mb-4">
              You may access the current version of these Terms of Use at any
              time by clicking on the link marked “Terms of Use” at the bottom
              of each page of the Site.
            </p>
          </section>

          {/* Waiver of Trial by Jury  Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Waiver of Trial by Jury
            </h2>
            <p className="text-gray-700 mb-4">
              YOU HEREBY WAIVE YOUR RIGHT TO A JURY TRIAL IN CONNECTION WITH ANY
              SUIT, ACTION OR PROCEEDING (ARISING IN CONTRACT, TORT OR
              OTHERWISE) ARISING FROM OR RELATED TO THE SUBSCRIPTION OR THESE
              TERMS AND CONDITIONS.
            </p>
          </section>

          {/* Notices Section */}
          <section className="mb-8">
            <h2 className="flex w-full text-xl font-playfair py-2 font-bold text-quoteText">
              Notices
            </h2>
            <p className="text-gray-700 mb-4">
              Contact information for help, subscription matters, and
              permissions is listed on the Site. You may also contact us as
              follows:
            </p>
            <p className="text-gray-700 mb-4">By email: sales@mypls.com</p>
            <p className="text-gray-700 mb-4">By phone: (717) 236-6984</p>
            <p className="text-gray-700 mb-4">
              Legal notices shall be mailed, certified mail, to: 240 North 3rd
              Street, 8th Floor, Harrisburg, PA 17101
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUse;
