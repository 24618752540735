import React, { useEffect, useState } from 'react';
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaUser,
  FaXTwitter
} from 'react-icons/fa6';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import HoverDropDown from '../HoverDropDown/HoverDropDown';
import {
  classRoomOption,
  publicationsOptions,
  publicationsOptions2,
  servicesOptions,
  servicesOptions2,
  userEmails,
  userOptions
} from '../../constants/constants';
import { Box, Button, Modal } from '@mui/material';
import ContactForm from '../ContactUs/ContactForm';
import { showToast } from '../../../../context/toastContext';
import { get_announcements } from '../../../../services/services';
import AnnouncementButton from '../AnnouncementButton/AnnouncementButton';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [openIndex, setOpenIndex] = useState(null); // Track which dropdown is open
  const [announcements, setAnnouncements] = useState([]);
  const handleOpen = index => {
    setOpenIndex(index);
  };

  const handleCloseDropDown = () => {
    setOpenIndex(null);
  };
  const [decodedToken, setDecodedToken] = useState(null);

  const checkLocation = path => {
    return location.pathname.includes(path);
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(!isOpen);
  };
  const fetchAnnouncements = async () => {
    try {
      const res = await get_announcements();
      setAnnouncements(res);
    } catch (err) {
      console.log('error fetching announcements');
    }
  };
  useEffect(() => {
    // Function to check if the token is expired
    fetchAnnouncements();
    const checkTokenExpiry = () => {
      // Get the token from local storage
      const tokenData = localStorage.getItem('authToken');

      if (tokenData) {
        try {
          // Decode the token (assuming it's stored as a JSON object)
          const decodedToken = JSON.parse(atob(tokenData));

          // Extract creation time and TTL
          const created = new Date(decodedToken.created);
          const ttl = decodedToken.ttl; // TTL in seconds

          // Calculate expiration time by adding TTL to creation time
          const expirationTime = new Date(created.getTime() + ttl * 1000); // ttl in milliseconds

          // Get current time
          const currentTime = new Date();

          // Check if token is expired
          if (currentTime > expirationTime) {
            // Token is expired, remove it from local storage
            console.log('Token expired, removing from local storage.');
            localStorage.removeItem('authToken');
          } else {
            console.log('Token is still valid.');
          }
        } catch (error) {
          console.error('Failed to decode token:', error);
        }
      }
    };

    // Run the token expiry check on component mount
    checkTokenExpiry();

    // You could also run this function periodically using setInterval, if needed
    // const interval = setInterval(checkTokenExpiry, 60000); // Check every minute

    // Cleanup interval on component unmount
    // return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const authToken = searchParams.get('auth');

    const authToken2 = localStorage.getItem('authToken');
    if (authToken) {
      try {
        const decodedToken = JSON.parse(atob(authToken));
        setDecodedToken(decodedToken);
        // Save auth token to localStorage
        localStorage.setItem('authToken', authToken);
        // Remove 'auth' from the query parameters
        removeAuthFromHash();
        fetchAnnouncements();
      } catch (error) {
        console.error('Invalid token:', error.message);
      }
    } else if (authToken2) {
      const decodedToken = JSON.parse(atob(authToken2));
      setDecodedToken(decodedToken);
    } else {
      console.log('Auth token is missing or not a valid string');
    }
  }, [searchParams]);
  const removeAuthFromHash = () => {
    showToast('Logged in successfully', 'success');
    const currentHash = window.location.hash; // Get the current hash
    const url = new URL(window.location.href); // Create a URL object

    // Remove 'auth' and the '?' if it's the only query parameter
    const cleanedHash = currentHash
      .replace(/(\?|&)auth=[^&]+(&|$)/, '$1')
      .replace(/\?$/, '');

    // Replace the current URL without reloading the page
    url.hash = cleanedHash;
    window.history.replaceState(null, '', url.toString());
  };

  const redirectUrl = encodeURIComponent(
    process.env.REACT_APP_OWN_URL + '#/home'
  );

  return (
    <div className="flex flex-col md:flex-row justify-between items-center w-full h-auto md:h-[100px] px-4 py-1 bg-purple2">
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3
          }}
        >
          <ContactForm email={'info@mypls.com'} onClose={handleClose} />
        </Box>
      </Modal>
      <div className="flex justify-between items-center w-full md:w-auto">
        {
          <img
            className="cursor-pointer"
            width={150}
            height={150}
            src="/img/logoimage.png"
            alt="Logo"
            onClick={() => navigate('/home')}
          />
        }
        <div className="block md:hidden justify-center items-center p-2 ml-3 cursor-pointer">
          {localStorage.getItem('authToken') ? (
            <HoverDropDown
              name={<FaUser size={20} color="#c396ec" />}
              options={userOptions
                .concat([
                  {
                    name: (
                      <div
                        className={`flex w-full px-[16px] py-[6px] text-xs text-purpleLight1 cursor-pointer outline-none`}
                      >
                        {decodedToken?.email}
                      </div>
                    )
                  }
                ])
                .reverse()}
              open={openIndex === 3}
              onOpen={() => handleOpen(3)}
              onClose={handleCloseDropDown}
            />
          ) : (
            <Button
              variant="contained"
              className={`${'bg-purple-500 p-0 hover:bg-blue-600'} text-white`}
              style={{ padding: '0px', backgroundColor: '#6c389c' }}
            >
              <a
                href={`https://clients.mypls.com/login?redirect=${redirectUrl}`}
                className="flex w-full px-[16px] py-[6px]"
              >
                Login
              </a>
            </Button>
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-7 items-center mt-4 md:mt-0">
        <div
          className={`text-xl ${
            checkLocation('/home') ? 'text-white' : 'text-purpleLight1'
          } cursor-pointer`}
          onClick={() => navigate('/home')}
        >
          Home
        </div>
        <div
          className={`text-xl ${
            checkLocation('publications') ? 'text-white' : 'text-purpleLight1'
          } cursor-pointer`}
        >
          <HoverDropDown
            name={'Publications'}
            options={
              localStorage.getItem('authToken')
                ? publicationsOptions
                : publicationsOptions2
            }
            isPath={checkLocation('publications')}
            open={openIndex === 0}
            onOpen={() => handleOpen(0)}
            onClose={handleCloseDropDown}
          />
        </div>
        <div
          className={`text-xl ${
            checkLocation('services') ? 'text-white' : 'text-purpleLight1'
          } cursor-pointer`}
        >
          <HoverDropDown
            name={'Services'}
            options={
              localStorage.getItem('authToken')
                ? servicesOptions
                : servicesOptions2
            }
            isPath={checkLocation('services')}
            open={openIndex === 1}
            onOpen={() => handleOpen(1)}
            onClose={handleCloseDropDown}
          />
        </div>
        {localStorage.getItem('authToken') && (
          <div
            className={`text-xl ${
              checkLocation('classroom') ? 'text-white' : 'text-purpleLight1'
            } cursor-pointer`}
          >
            <HoverDropDown
              name={'Classroom'}
              options={classRoomOption}
              isPath={checkLocation('classroom')}
              open={openIndex === 2}
              onOpen={() => handleOpen(2)}
              onClose={handleCloseDropDown}
            />
          </div>
        )}
        <div
          className={`text-xl ${
            checkLocation('about-us') ? 'text-white' : 'text-purpleLight1'
          } cursor-pointer`}
          onClick={() => navigate('/about-us')}
        >
          PLS Team
        </div>
        {userEmails.includes(decodedToken?.email) &&
          localStorage.getItem('authToken') && (
            <Button
              variant="contained"
              className={`${'hover:opacity-60'} text-white`}
              style={{
                padding: '6px',
                backgroundColor: '#6c389c',
                textTransform: 'capitalize',
                fontSize: '16px'
              }}
              onClick={() => navigate('/seminars')}
            >
              Dashboard
            </Button>
          )}
      </div>
      <div className="flex gap-3 items-center mt-4 md:mt-0">
        {announcements?.length > 0 &&
          announcements.some(ann => ann?.isActive) &&
          localStorage.getItem('authToken') && (
            <AnnouncementButton
              count={announcements?.length}
              announcements={announcements}
            />
          )}
        <a
          href="https://www.facebook.com/palegislativeservices/"
          target="_blank"
          className="flex justify-center items-center p-2 bg-white rounded-full cursor-pointer"
          rel="noreferrer"
        >
          <FaFacebookF size={20} />
        </a>
        <a
          href="https://twitter.com/MyPLS"
          target="_blank"
          className="flex justify-center items-center p-2 bg-white rounded-full cursor-pointer"
          rel="noreferrer"
        >
          <FaXTwitter size={20} />
        </a>
        <a
          href="https://www.instagram.com/my.pls/"
          target="_blank"
          className="flex justify-center items-center p-2 bg-white rounded-full cursor-pointer"
          rel="noreferrer"
        >
          <FaInstagram size={20} />
        </a>
        <div
          className="flex justify-center items-center p-2 bg-white rounded-full cursor-pointer"
          onClick={handleClose}
        >
          <FaEnvelope size={20} />
        </div>
        <div className="hidden md:flex justify-center items-center p-2 ml-3 cursor-pointer">
          {localStorage.getItem('authToken') ? (
            <HoverDropDown
              name={<FaUser size={20} color="#c396ec" />}
              options={userOptions
                .concat([
                  {
                    name: (
                      <div
                        className={`flex w-full px-[16px] py-[6px] text-xs text-purpleLight1 cursor-pointer outline-none`}
                      >
                        {decodedToken?.email}
                      </div>
                    )
                  }
                ])
                .reverse()}
              open={openIndex === 3}
              onOpen={() => handleOpen(3)}
              onClose={handleCloseDropDown}
            />
          ) : (
            <Button
              variant="contained"
              className={`${'bg-purple-500 p-0 hover:bg-blue-600'} text-white`}
              style={{ padding: '0px', backgroundColor: '#6c389c' }}
            >
              <a
                href={`https://clients.mypls.com/login?redirect=${redirectUrl}`}
                className="flex w-full px-[16px] py-[6px]"
              >
                Login
              </a>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
