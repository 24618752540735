import { getAxiosInstance } from '../configs/interceptor';
import { showToast } from '../context/toastContext';

// GET CONFIG
const createUrl = (endpoint, params) => {
  const searchParams = new URLSearchParams(params);
  return `${endpoint}?${searchParams.toString()}`;
};
export const GET_CONFIG = async slug => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.get(`/config-data/page/${slug}`);
    return res;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

// GET DATA

export const GET_DATA = async (page, params) => {
  const urlWithParams = createUrl(page, params);
  try {
    const axios = await getAxiosInstance();
    const res = await axios.get(`/${urlWithParams}`);
    return res;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

//////seminars types
export const get_seminars_types = async () => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.get(`/seminar-types`);
    return res.data;
  } catch (error) {
    console.error('Error fetching seminar types:', error);
  }
};

export const create_seminars_types = async (data, fetch_types) => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.post(`/seminar-types`, data);
    showToast('Seminar type Created', 'success');
    fetch_types();
    return res;
  } catch (error) {
    console.error('Error fetching seminar types:', error);
  }
};

export const update_seminars_types = async (data, id, fetch_types) => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.patch(`/seminar-types/${id}`, data);
    showToast('Seminar type Updated', 'success');
    fetch_types();
    return res;
  } catch (error) {
    console.error('Error fetching seminar types:', error);
  }
};
export const delete_seminars_types = async (id, fetch_types) => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.delete(`/seminar-types/${id}`);
    showToast('Seminar type deleted', 'success');
    fetch_types();
    return res;
  } catch (error) {
    console.error('Error fetching seminar types:', error);
  }
};

//////seminars
export const get_seminars = async () => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.get(`/seminars`);
    return res.data;
  } catch (error) {
    console.error('Error fetching seminar types:', error);
  }
};

export const create_seminars = async (data, fetchSeminars) => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.post(`/seminars`, data);
    showToast('Seminar Created', 'success');
    fetchSeminars();
    return res;
  } catch (error) {
    console.error('Error fetching seminar types:', error);
  }
};

export const update_seminar = async (data, id, fetchSeminars) => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.patch(`/seminars/${id}`, data);
    showToast('Seminar Created', 'success');
    fetchSeminars();
    return res;
  } catch (error) {
    console.error('Error fetching seminar types:', error);
  }
};

export const delete_seminars = async (id, fetchSeminars) => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.delete(`/seminars/${id}`);
    showToast('Seminar Deleted', 'success');
    fetchSeminars();
    return res.data;
  } catch (error) {
    console.error('Error fetching seminar types:', error);
  }
};

/////seminar registrations
export const get_seminars_registrations = async () => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.get(`/seminar-registrations`);
    return res;
  } catch (error) {
    console.error('Error fetching seminar types:', error);
  }
};

export const create_seminars_registrations = async data => {
  try {
    const axios = await getAxiosInstance();
    await axios.post(`/seminar-registrations`, data);
    showToast('Registered Successfully', 'success');
    return true;
  } catch (error) {
    showToast('There was an error registering for the seminar', 'error');
    console.error('Error fetching seminar types:', error);
    return false;
  }
};

/////job openings
export const get_job_openings = async () => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.get(`/careers`);
    return res.data;
  } catch (error) {
    console.error('Error fetching Jobs:', error);
  }
};

export const create_job_openings = async (data, fetchJobOpenings) => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.post(`/careers`, data);
    showToast('Job Created Successfully', 'success');
    fetchJobOpenings();
    return res;
  } catch (error) {
    console.error('Error creating jobs:', error);
  }
};

export const delete_job_openings = async (id, fetchJobOpenings) => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.delete(`/careers/${id}`);
    showToast('Job Deleted Successfully', 'success');
    fetchJobOpenings();
    return res;
  } catch (error) {
    console.error('Error creating jobs:', error);
  }
};

export const update_job_opening = async (data, id, fetchJobOpenings) => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.patch(`/careers/${id}`, data);
    showToast('Job opening updated', 'success');
    fetchJobOpenings();
    return res;
  } catch (error) {
    console.error('Error updating job opening:', error);
  }
};

/////Announcements
export const get_announcements = async () => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.get(`/announcement`);
    return res.data;
  } catch (error) {
    console.error('Error fetching announcement:', error);
  }
};

export const create_announcements = async (data, fetchAnnouncements) => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.post(`/announcement`, data);
    showToast('Announcement Created Successfully', 'success');
    fetchAnnouncements();
    return res;
  } catch (error) {
    console.error('Error creating announcement:', error);
  }
};

export const delete_announcements = async (id, fetchAnnouncements) => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.delete(`/announcement/${id}`);
    showToast('Announcement Deleted Successfully', 'success');
    fetchAnnouncements();
    return res;
  } catch (error) {
    console.error('Error deleting announcement:', error);
  }
};

export const update_announcement = async (data, id, fetchAnnouncements) => {
  try {
    const axios = await getAxiosInstance();
    const res = await axios.patch(`/announcement/${id}`, data);
    showToast('Announcement updated', 'success');
    fetchAnnouncements();
    return res;
  } catch (error) {
    console.error('Error updating announcement:', error);
  }
};
