import { Box, Modal } from '@mui/material';
import React, { useState } from 'react';
import ContactForm from '../ContactUs/ContactForm';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(!isOpen);
  };
  return (
    <footer className="bg-gray-800 text-white py-4 w-full">
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3
          }}
        >
          <ContactForm email={'info@mypls.com'} onClose={handleClose} />
        </Box>
      </Modal>
      <div className="container mx-auto text-center">
        <div className="mb-2">
          <a className="mx-2 hover:underline" onClick={handleClose}>
            Contact Us
          </a>
          |
          <a
            className="mx-2 hover:underline"
            onClick={() => navigate('/careers')}
          >
            Careers
          </a>
          |
          <a
            className="mx-2 hover:underline"
            onClick={() => navigate('/terms-and-conditions')}
          >
            Terms of Use
          </a>
        </div>
        <div className="text-sm">
          Pennsylvania Legislative Services, All Rights Reserved | © 2001–2024
        </div>
      </div>
    </footer>
  );
};

export default Footer;
