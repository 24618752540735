import React, { useEffect, useState } from 'react';
import JobFormModal from './JobOpeningModal';
import {
  create_job_openings,
  delete_job_openings,
  get_job_openings,
  update_job_opening
} from '../../../../services/services';
import { Delete, Edit } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

const JobTable = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const openModal = (job = null) => {
    setSelectedJob(job);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedJob(null);
  };

  const handleCreate = newJob => {
    create_job_openings(newJob, fetchJobOpenings);
  };

  const handleUpdate = updatedJob => {
    const data = {
      description: updatedJob.description,
      jobTitle: updatedJob.jobTitle,
      location: updatedJob.location,
      recipientEmail: updatedJob.recipientEmail
    };
    update_job_opening(data, updatedJob._id, fetchJobOpenings);
  };

  const handleDelete = id => {
    delete_job_openings(id, fetchJobOpenings);
  };

  const fetchJobOpenings = async () => {
    const data = await get_job_openings();
    setJobs(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchJobOpenings();
  }, []);

  return (
    <div className="container mx-auto p-4">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="flex justify-end mb-4">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
              onClick={() => openModal()}
            >
              Create Job Opening
            </button>
          </div>

          <table className="table-auto w-full text-left border">
            <thead className="bg-gray-200">
              <tr>
                <th className="p-2 border">Job Title</th>
                <th className="p-2 border">Job Location</th>
                <th className="p-2 border">Job Description</th>
                <th className="p-2 border">Recipient Email</th>
                <th className="p-2 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {jobs?.map(job => (
                <tr key={job._id} className="border-t">
                  <td className="p-2">{job?.jobTitle}</td>
                  <td className="p-2">{job?.location}</td>
                  <td className="p-2">{job?.description}</td>
                  <td className="p-2">{job?.recipientEmail}</td>
                  <td className="p-2 flex space-x-2">
                    <button
                      className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600 transition"
                      onClick={() => openModal(job)}
                    >
                      <Edit />
                    </button>
                    <button
                      className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 transition"
                      onClick={() => handleDelete(job._id)}
                    >
                      <Delete />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {isModalOpen && (
            <JobFormModal
              closeModal={closeModal}
              onSubmit={selectedJob ? handleUpdate : handleCreate}
              initialValues={selectedJob}
            />
          )}
        </>
      )}
    </div>
  );
};

export default JobTable;
