import React, { useEffect } from 'react';
import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';
import SeminarTypeTable from '../../components/SeminarTypes/SeminarTypeTable';
import { useNavigate } from 'react-router-dom';

const SeminarTypes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if token exists in localStorage
    if (!localStorage.getItem('authToken')) {
      navigate('/home'); // Redirect to home page
    }
  }, [navigate]);
  return (
    <DashboardLayout>
      <SeminarTypeTable />
    </DashboardLayout>
  );
};

export default SeminarTypes;
