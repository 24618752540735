import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import * as Emails from '../../../../services/email';
import { showToast } from '../../../../context/toastContext';
import { ReCaptcha } from '../../../../components/ReCaptcha/ReCaptcha';

import { FreeTrialEmail } from './FreeTrialEmail';

const FreeTrialForm = ({ onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      organization: '',
      captcha: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      phone: Yup.string().required('Phone number is required'),
      organization: Yup.string().required('Organization is required'),
      captcha: Yup.string().required('Please complete the CAPTCHA') // Require captcha
    }),
    onSubmit: async (values, actions) => {
      if (!values.captcha) {
        // If CAPTCHA is not completed, prevent submission
        actions.setFieldError('captcha', 'Please complete the CAPTCHA');
        return;
      }
      setIsSubmitting(true);
      try {
        await Emails.sendEmail(
          {
            from: { name: 'Free Trial Form', email: 'mypls@mypls.com' },
            to: [{ name: 'MyPLS', email: 'sales@mypls.com' }],
            replyTo: { name: values.name, email: values.email },
            subject: 'Free Trial Request',
            html: ReactDOMServer.renderToStaticMarkup(
              <FreeTrialEmail values={values} />
            ),
            captcha: captchaValue
          },
          'Free Trial Request Submitted',
          onClose
        );
        actions.resetForm();
        setCaptchaValue(null); // Reset CAPTCHA
      } catch (error) {
        console.error(`Error sending email: ${error.message}`);
        showToast(
          'Error sending email. Please try again, or email sales@mypls.com directly',
          'error'
        );
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  const handleCaptchaChange = value => {
    setCaptchaValue(value);
    formik.setFieldValue('captcha', value);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 w-full h-full shadow-lg">
      <div className="bg-white rounded-lg w-full max-w-lg transform transition-all duration-500 ease-in-out hover:scale-105">
        <div className="w-full bg-white p-8 shadow-lg rounded-md">
          <h2 className="text-2xl font-semibold mb-4">
            PLS Free Trial Request
          </h2>
          <p className="text-gray-700 mb-6">Tell us a little about yourself.</p>

          <form onSubmit={formik.handleSubmit}>
            {/* Name Field */}
            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                What is your name? <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="name"
                className={`w-full p-2 border ${
                  formik.errors.name && formik.touched.name
                    ? 'border-red-500'
                    : 'border-gray-300'
                } rounded-md focus:ring focus:ring-blue-300 outline-none`}
                placeholder="Jenny Smith"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.name}
                </div>
              ) : null}
            </div>

            {/* Email Field */}
            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                What is your email address?{' '}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="email"
                className={`w-full p-2 border ${
                  formik.errors.email && formik.touched.email
                    ? 'border-red-500'
                    : 'border-gray-300'
                } rounded-md focus:ring focus:ring-blue-300 outline-none`}
                placeholder="jenny@example.com"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>

            {/* Phone Field */}
            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                What is a phone number we can use to reach you?{' '}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="tel"
                name="phone"
                className={`w-full p-2 border ${
                  formik.errors.phone && formik.touched.phone
                    ? 'border-red-500'
                    : 'border-gray-300'
                } rounded-md focus:ring focus:ring-blue-300 outline-none`}
                placeholder="717-867-5309"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.phone}
                </div>
              ) : null}
            </div>

            {/* Organization Field */}
            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                What organization are you affiliated with?{' '}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="organization"
                className={`w-full p-2 border ${
                  formik.errors.organization && formik.touched.organization
                    ? 'border-red-500'
                    : 'border-gray-300'
                } rounded-md focus:ring focus:ring-blue-300 outline-none`}
                placeholder="Examples Inc."
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.organization}
              />
              {formik.touched.organization && formik.errors.organization ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.organization}
                </div>
              ) : null}
            </div>

            {/* ReCAPTCHA */}
            <div className="mb-4">
              <ReCaptcha onChange={handleCaptchaChange} />
              {formik.touched.captcha && formik.errors.captcha ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.captcha}
                </div>
              ) : null}
            </div>

            {/* Submit Button */}
            <div className="flex justify-center mt-6">
              <button
                type="submit"
                className="
                  px-6 
                  py-2 
                  bg-blue-500 
                  text-white 
                  rounded-md 
                  hover:bg-blue-600 
                  transition 
                  duration-300 
                  ease-in-out 
                  disabled:bg-gray-400 
                  disabled:text-gray-700 
                  disabled:cursor-not-allowed 
                  disabled:opacity-50"
                disabled={!captchaValue || isSubmitting} // Disable button until CAPTCHA is completed
              >
                {isSubmitting ? 'Sending...' : 'Request Free Trial'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FreeTrialForm;
