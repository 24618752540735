import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { FaPlay } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';
import { showToast } from '../../../../context/toastContext';
import './VideoPlayer.css';

const VideoPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoUrl = 'https://youtu.be/ncpCMHwm2LI'; // Replace with your video URL

  const copyToClipboard = () => {
    navigator.clipboard.writeText(videoUrl);
    showToast('Link Copied', 'success');
  };

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
  };

  const handleVideoEnd = () => {
    setIsPlaying(false); // Reset to show background image and play button again
  };

  return (
    <div
      className="relative bg-black overflow-hidden shadow-lg w-full h-[322px]"
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: isPlaying ? 'none' : 'url(img/video-logo.png)'
      }}
    >
      {isPlaying ? (
        <div>
          <ReactPlayer
            url={videoUrl}
            playing
            controls
            width="100%"
            height="322px"
            onEnded={handleVideoEnd} // Handle video end
            config={{
              youtube: {
                playerVars: { fs: 0 },
                modestbranding: 1,
                rel: 0,
                showinfo: 0
              }
            }}
          />
        </div>
      ) : (
        <div className="flex justify-center items-center h-full w-full">
          <button
            onClick={handlePlayButtonClick}
            className="flex items-center justify-center p-4 bg-red-600 rounded-full text-white shadow-lg hover:bg-red-700 transition"
          >
            <FaPlay className="h-8 w-8" />
          </button>
        </div>
      )}
      <button
        onClick={copyToClipboard}
        className="absolute flex flex-col top-3 right-3 p-2 bg-transparent text-black justify-center items-center rounded-full shadow transition"
      >
        <MdContentCopy color="white" size={20} />
        <p className="text-sm font-medium text-white">Copy link</p>
      </button>
    </div>
  );
};

export default VideoPlayer;
