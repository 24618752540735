import React, { useEffect, useState } from 'react';
import SeminarFormModal from './SeminarFormModal';
import {
  create_seminars,
  delete_seminars,
  get_seminars,
  update_seminar
} from '../../../../services/services';
import { Delete, Edit } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

const SeminarTable = () => {
  const [seminars, setSeminars] = useState([]);
  const [selectedSeminar, setSelectedSeminar] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const openModal = (seminar = null) => {
    setSelectedSeminar(seminar);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSeminar(null);
  };

  const handleCreate = newSeminar => {
    const data = {
      seminarType: newSeminar.type,
      scheduled: newSeminar.date
    };
    create_seminars(data, fetchSeminars);
  };

  const handleUpdate = (updatedSeminar, id) => {
    const data = {
      seminarType: updatedSeminar.type,
      scheduled: updatedSeminar.date
    };
    update_seminar(data, id, fetchSeminars);
  };
  const handleDelete = id => {
    delete_seminars(id, fetchSeminars);
  };
  const fetchSeminars = async () => {
    const data = await get_seminars();
    setSeminars(data || []);
    setLoading(false);
  };

  useEffect(() => {
    fetchSeminars();
  }, []);

  return (
    <div className="container mx-auto p-4">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="flex justify-end mb-4">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
              onClick={() => openModal()}
            >
              Create Seminar
            </button>
          </div>

          <table className="table-auto w-full text-left border">
            <thead className="bg-gray-200">
              <tr>
                <th className="p-2 border">Seminar Name</th>
                <th className="p-2 border">Seminar Date & Time</th>
                <th className="p-2 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {seminars.map(seminar => (
                <tr key={seminar._id} className="border-t">
                  <td className="p-2">{seminar.seminarType.title}</td>
                  <td className="p-2">
                    {new Date(seminar.scheduled).toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true
                    })}
                  </td>
                  <td className="p-2 flex space-x-2">
                    <button
                      className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600 transition"
                      onClick={() => openModal(seminar)}
                    >
                      <Edit />
                    </button>
                    <button
                      className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 transition"
                      onClick={() => handleDelete(seminar._id)}
                    >
                      <Delete />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {isModalOpen && (
            <SeminarFormModal
              closeModal={closeModal}
              onSubmit={selectedSeminar ? handleUpdate : handleCreate}
              initialValues={selectedSeminar}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SeminarTable;
