import { Link } from 'react-router-dom';
export const redirectUrlPressPass = encodeURIComponent(
  'https://press-pass.mypls.com'
);
export const redirectUrlGovTrac = encodeURIComponent('https://plsgovtrac.com');
export const redirectUrlPaGov2Go = encodeURIComponent(
  'https://www.pagov2go.com'
);
export const redirectUrlPlsPublications = encodeURIComponent(
  'https://publications.mypls.com/'
);
const redirectUrl = encodeURIComponent(
  process.env.REACT_APP_OWN_URL + '#/home'
);

export const publicationsOptions = [
  {
    name: (
      <a
        href={`https://clients.mypls.com/login?redirect=${redirectUrlPlsPublications}`}
        className="flex w-full px-[16px] py-[6px]"
      >
        Bullet.In.Points
      </a>
    )
  },
  {
    name: (
      <a
        href="https://member-bios.mypls.com"
        className="flex w-full px-[16px] py-[6px]"
      >
        Member Bios
      </a>
    )
  },
  {
    name: (
      <a
        href={`https://clients.mypls.com/login?redirect=${redirectUrlPlsPublications}`}
        className="flex w-full px-[16px] py-[6px]"
      >
        Capitol Recap
      </a>
    )
  },
  {
    name: (
      <a
        href={`https://clients.mypls.com/login?redirect=${redirectUrlPlsPublications}`}
        className="flex w-full px-[16px] py-[6px]"
      >
        Education Recap
      </a>
    )
  },
  {
    name: (
      <a
        href={`https://clients.mypls.com/login?redirect=${redirectUrlPlsPublications}`}
        className="flex w-full px-[16px] py-[6px]"
      >
        Health Care Recap
      </a>
    )
  },
  // {
  //     name:<a href={`https://clients.mypls.com/login?redirect=${redirectUrlPlsPublications}`} className='flex w-full px-[16px] py-[6px]'>How a Bill Becomes a Law</a>
  // }
  {
    name: (
      <Link
        to={'/publications/how-a-bill-becomes-a-law'}
        className="flex w-full px-[16px] py-[6px]"
      >
        How a Bill Becomes a Law
      </Link>
    )
  },
  {
    name: (
      <a
        href={`https://clients.mypls.com/login?redirect=${redirectUrlPlsPublications}`}
        className="flex w-full px-[16px] py-[6px]"
      >
        Transportation Recap
      </a>
    )
  }
];

export const publicationsOptions2 = [
  {
    name: (
      <Link
        to={'/publications/bullet-in-points'}
        className="flex w-full px-[16px] py-[6px]"
      >
        Bullet.In.Points
      </Link>
    )
  },
  {
    name: (
      <Link
        to={'/publications/member-bios'}
        className="flex w-full px-[16px] py-[6px]"
      >
        Member Bios
      </Link>
    )
  },
  {
    name: (
      <Link
        to={'/publications/capitol-recap'}
        className="flex w-full px-[16px] py-[6px]"
      >
        Capitol Recap
      </Link>
    )
  },
  // ,
  // {
  //     name:<Link to={"/publications/education-recap"} className='flex w-full px-[16px] py-[6px]'>Education Recap</Link>
  // }
  // ,
  // {
  //     name:<Link to={"/publications/health-care-recap"} className='flex w-full px-[16px] py-[6px]'>Health Care Recap</Link>
  // }
  {
    name: (
      <Link
        to={'/publications/how-a-bill-becomes-a-law'}
        className="flex w-full px-[16px] py-[6px]"
      >
        How a Bill Becomes a Law
      </Link>
    )
  },
  // ,
  // {
  //     name:<Link to={"/publications/transportation-recap"} className='flex w-full px-[16px] py-[6px]'>Transportation Recap</Link>
  // }
  {
    name: (
      <Link
        to={'/publications/industry-recaps'}
        className="flex w-full px-[16px] py-[6px]"
      >
        Transportation Recap
      </Link>
    )
  }
];

export const servicesOptions = [
  {
    name: (
      <a
        href={`https://clients.mypls.com/login?redirect=${redirectUrlGovTrac}`}
        className="flex w-full px-[16px] py-[6px]"
      >
        PLSGovTrac
      </a>
    )
  },
  {
    name: (
      <a
        href={`https://clients.mypls.com/login?redirect=${redirectUrlPaGov2Go}`}
        className="flex w-full px-[16px] py-[6px]"
      >
        PAGOV2GO
      </a>
    )
  },
  {
    name: (
      <a
        href={`https://clients.mypls.com/login?redirect=${redirectUrlPressPass}`}
        className="flex w-full px-[16px] py-[6px]"
      >
        Communication Connection
      </a>
    )
    // name:<Link to={"/communication-connection/"} className='flex w-full px-[16px] py-[6px]'>Communication Connection</Link>
  }
];
export const servicesOptions2 = [
  {
    name: (
      <Link
        to={'/services/plsgovtrac'}
        className="flex w-full px-[16px] py-[6px]"
      >
        PLSGovTrac
      </Link>
    )
  },
  {
    name: (
      <Link
        to={'/services/pagov2go'}
        className="flex w-full px-[16px] py-[6px]"
      >
        PAGOV2GO
      </Link>
    )
  },
  {
    name: (
      <Link
        to={'/services/communication-connection'}
        className="flex w-full px-[16px] py-[6px]"
      >
        Communication Connection
      </Link>
    )
  }
];
export const classRoomOption = [
  {
    name: (
      <Link
        to={'/classroom/up-coming-seminars'}
        className="flex w-full px-[16px] py-[6px]"
      >
        Upcoming Seminars
      </Link>
    )
  },
  {
    name: (
      <Link
        to={'/classroom/seminar-registration'}
        className="flex w-full px-[16px] py-[6px]"
      >
        Seminar Registration
      </Link>
    )
  },
  {
    name: (
      <Link
        to={'/classroom/reference-materials'}
        className="flex w-full px-[16px] py-[6px]"
      >
        Reference Materials
      </Link>
    )
  }
];
export const userOptions = [
  {
    name: (
      <a
        href={`https://clients.mypls.com/logout?redirect=${redirectUrl}`}
        className="flex w-full px-[16px] py-[6px] text-base font-playfair"
        onClick={() => localStorage.removeItem('authToken')}
      >
        Logout
      </a>
    )
  }
];

export const userEmails = [
  'deborah@mypls.com',
  'nouman.malik@mypls.com',
  'libby@mypls.com',
  'justin.fields@mypls.com',
  'aaron.hannon@mypls.com'
];
