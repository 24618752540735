import React, { Suspense, lazy } from 'react';
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import Home from './Apps/myplsMainApp/pages/Home/Home';
import PLSTeam from './Apps/myplsMainApp/pages/PLSTeam/PLSTeam';
import Classroom from './Apps/myplsMainApp/pages/Classroom/Classroom';
import ServicesContent from './Apps/myplsMainApp/pages/ServicesContent/ServicesContent';
import TermsOfUse from './Apps/myplsMainApp/pages/TermsAndConditions/TermsAndConditions';
import PublicationsContent from './Apps/myplsMainApp/pages/PublicationsContent/PublicationsContent';
import Careers from './Apps/myplsMainApp/pages/Careers/Careers';
import Seminars from './Apps/myplsMainApp/pages/Seminars/Seminars';
import JobOpeningsPage from './Apps/myplsMainApp/pages/JobOpenings/JobOpenings';
import SeminarTypes from './Apps/myplsMainApp/pages/SeminarTypes/SeminarTypes';
// import TeamBios from './Apps/myplsMainApp/pages/TeamBios/TeamBios';
import PLSGovTracGuide from './Apps/myplsMainApp/pages/Classroom/ReferenceMaterials/PLSGovtracGuide';
import Announcements from './Apps/myplsMainApp/pages/Announcements/Announcements';

const CommunicationConnection = lazy(
  () => import('./routes/communicationConnection')
);
const Service2 = lazy(() => import('./routes/communicationConnection'));

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route index path="/home" element={<Home />} />
          <Route path="/about-us" element={<PLSTeam />} />
          <Route path="/classroom/up-coming-seminars" element={<Classroom />} />
          <Route path="/terms-and-conditions" element={<TermsOfUse />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/seminars" element={<Seminars />} />
          <Route path="/job-openings" element={<JobOpeningsPage />} />
          <Route path="/seminar-types" element={<SeminarTypes />} />
          {/* <Route path='/team-bios' element={<TeamBios/>}/> */}
          <Route path="/announcements" element={<Announcements />} />
          <Route
            path="/classroom/seminar-registration"
            element={<Classroom />}
          />
          <Route
            path="/classroom/reference-materials"
            element={<Classroom />}
          />
          <Route
            path="/classroom/reference-materials/PLSGovtracCollaborationGuide"
            element={<PLSGovTracGuide />}
          />
          <Route path="/services/:serviceName" element={<ServicesContent />} />
          <Route
            path="/publications/:publicationName"
            element={<PublicationsContent />}
          />
          <Route
            path="/communication-connection/*"
            element={<CommunicationConnection />}
          />
          <Route path="/service2/*" element={<Service2 />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
