import React from 'react';

const GovTracContent = () => {
  return (
    <div className="bg-white p-8 mx-auto my-10 rounded-lg shadow-md max-w-5xl">
      <h2 className="text-3xl font-bold text-center mb-6">PLS GovTrac</h2>

      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">
          Empower Your Advocacy with PLSGovTrac – The Premier Legislative
          Tracking Service for Pennsylvania Professionals
        </h3>
        <p className="text-gray-700 mb-4">
          In the dynamic landscape of Pennsylvania’s state legislature, staying
          ahead of the curve is crucial. Whether you’re a lobbyist, government
          affairs professional, or legislative advocate, PLSGovTrac is your
          ultimate tool for navigating the complexities of the Commonwealth’s
          General Assembly.
        </p>
        <p className="font-bold mb-4">
          Why PLSGovTrac is the Preferred Choice for Pennsylvania Legislative
          Professionals:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Real-Time Legislative Tracking:</strong> With PLSGovTrac,
            you can monitor every bill, amendment, and legislative action as it
            happens. Stay informed on the progress of legislation, from
            introduction to final passage, and never miss a critical
            development.
          </li>
          <li>
            <strong>Advanced Research Tools:</strong> PLSGovTrac provides
            powerful search and filtering capabilities that allow you to dig
            deep into Pennsylvania’s legislative history. Whether you need
            detailed bill summaries, key vote records, or historical legislative
            data, our platform makes it easy to find exactly what you need.
          </li>
          <li>
            <strong>Access to Legislative Floor Transcripts:</strong> Every word
            spoken on the floor of the Pennsylvania General Assembly is crucial,
            and with PLSGovTrac, you won’t miss a single one. Our service
            provides you with real-time access to full transcripts of
            legislative floor debates, discussions, and votes. This allows you
            to:
            <ul className="list-disc list-inside ml-5">
              <li>
                <strong>Stay informed:</strong> Follow the nuances of debates
                and understand the context behind legislative decisions.
              </li>
              <li>
                <strong>Analyze Arguments:</strong> Review legislators’
                statements to anticipate future legislative moves and shape your
                advocacy strategies accordingly.
              </li>
              <li>
                <strong>Prepare Testimony:</strong> Use exact quotes from floor
                debates to strengthen your case when presenting to committees or
                stakeholders.
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h4 className="text-lg font-semibold mb-4">
          Comprehensive Committee Meeting Coverage
        </h4>
        <p className="text-gray-700 mb-4">
          Committee meetings are where the real work of the legislature happens.
          With PLSGovTrac, you have access to detailed coverage of all committee
          meetings, including:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Meeting Transcripts:</strong> Read through full transcripts
            of committee discussions, so you can understand the intricacies of
            policy formation and the rationale behind legislative amendments.
          </li>
          <li>
            <strong>Critical Insights:</strong> Gain insights into committee
            dynamics and anticipate how legislation might be shaped before it
            reaches the floor.
          </li>
          <li>
            <strong>Strategic Advantage:</strong> Stay ahead of the curve by
            knowing exactly what was discussed and how it may influence upcoming
            votes.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h4 className="text-lg font-semibold mb-4">
          Detailed Legislative Summaries
        </h4>
        <p className="text-gray-700 mb-4">
          Cut through the complexity of legislative language with PLSGovTrac’s
          expertly crafted legislative summaries. Our summaries provide:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Clear Overviews:</strong> Quickly grasp the essence of any
            bill, amendment, or resolution with concise, plain-language
            summaries.
          </li>
          <li>
            <strong>Key Takeaways:</strong> Identify the most critical points
            and potential impacts of legislation at a glance, saving you time
            and effort.
          </li>
          <li>
            <strong>Enhanced Advocacy:</strong> Use summaries to effectively
            communicate the importance of legislation to your stakeholders,
            clients, or constituents.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h4 className="text-lg font-semibold mb-4">
          Custom Alerts and Notifications
        </h4>
        <p className="text-gray-700 mb-4">
          Tailor your PLSGovTrac experience with personalized alerts and
          notifications. Get real-time updates on the issues, legislators, and
          committees that matter most to you, delivered directly to your inbox.
        </p>
      </section>

      <section className="mb-8">
        <h4 className="text-lg font-semibold mb-4">
          Comprehensive Bill Information
        </h4>
        <p className="text-gray-700 mb-4">
          Access everything you need to know about any piece of legislation.
          From plain-language summaries to full bill texts and legislative
          histories, PLSGovTrac provides a complete picture so you can make
          informed decisions.
        </p>
      </section>

      <section className="mb-8">
        <h4 className="text-lg font-semibold mb-4">
          Detailed Legislator Profiles
        </h4>
        <p className="text-gray-700 mb-4">
          Connect with the people who shape Pennsylvania’s laws. Our extensive
          legislative directory includes in-depth profiles of every legislator,
          complete with contact information, committee assignments, voting
          records, and more.
        </p>
      </section>

      <section className="mb-8">
        <h4 className="text-lg font-semibold mb-4">
          Interactive Legislative Calendars
        </h4>
        <p className="text-gray-700 mb-4">
          Stay on top of the legislative schedule with our interactive
          calendars. Track upcoming sessions, committee meetings, and public
          hearings, and set reminders to ensure you’re always prepared for key
          events.
        </p>
      </section>

      <section className="mb-8">
        <h4 className="text-lg font-semibold mb-4">
          Document Management and Reporting
        </h4>
        <p className="text-gray-700 mb-4">
          PLSGovTrac makes it easy to manage and share legislative documents.
          Generate detailed reports, export data for further analysis, and
          collaborate seamlessly with your team.
        </p>
      </section>

      <section className="mb-8">
        <h4 className="text-lg font-semibold mb-4">
          In-Depth Analytical Tools
        </h4>
        <p className="text-gray-700 mb-4">
          Leverage PLSGovTrac’s analytical features to understand voting
          patterns, evaluate the impact of legislation, and track trends over
          time. Our platform gives you the insights you need to strategize and
          advocate effectively.
        </p>
      </section>

      <section className="mb-8">
        <h4 className="text-lg font-semibold mb-4">
          Integration with Your Tools
        </h4>
        <p className="text-gray-700 mb-4">
          PLSGovTrac integrates effortlessly with your existing systems,
          allowing you to manage email campaigns, and create custom reports that
          align with your workflow.
        </p>
      </section>

      <section className="mb-8">
        <h4 className="text-lg font-semibold mb-4">
          Unmatched Support and Training
        </h4>
        <p className="text-gray-700 mb-4">
          Our dedicated support team is here to ensure you get the most out of
          PLSGovTrac. With comprehensive training resources, customization
          options, and responsive user support, we’re committed to your success.
        </p>
      </section>

      <section className="mt-10">
        <h4 className="text-lg font-semibold mb-4">
          Experience the Power of Legislative Intelligence with PLSGovTrac
        </h4>
        <p className="text-gray-700 mb-4">
          PLSGovTrac is more than just a legislative tracking service – it’s a
          complete legislative intelligence platform designed to empower
          Pennsylvania’s legislative professionals. With our state-of-the-art
          tools and comprehensive resources, you’ll have everything you need to
          navigate the complexities of state government and achieve your
          advocacy goals.
        </p>
        <p className="font-semibold text-center">
          Subscribe today and join the ranks of Pennsylvania’s most informed and
          effective legislative professionals. With PLSGovTrac, you’re always
          one step ahead.
        </p>
      </section>
    </div>
  );
};

export default GovTracContent;
