import React from 'react';

export function FreeTrialEmail({
  values: { name, email, phone, organization }
}) {
  return (
    <html>
      <body>
        <h2>Free Trial Request</h2>
        <p>
          <table>
            <tbody>
              <tr>
                <td>Name: </td>
                <td>{name}</td>
              </tr>
              <tr>
                <td>Email: </td>
                <td>
                  <a href={`mailto:${email}?subject=Your Free Trial of PLS!`}>
                    {email}
                  </a>
                </td>
              </tr>
              <tr>
                <td>Phone: </td>
                <td>
                  <a href={`tel:${phone}`}>{phone}</a>
                </td>
              </tr>
              <tr>
                <td>Company: </td>
                <td>{organization}</td>
              </tr>
            </tbody>
          </table>
        </p>
        <p>
          <b>
            <a href="https://clients.mypls.com/invite">Create User Page</a>
          </b>
        </p>
      </body>
    </html>
  );
}
