import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import * as Emails from '../../../../services/email';
import { showToast } from '../../../../context/toastContext';
import { ReCaptcha } from '../../../../components/ReCaptcha/ReCaptcha';

// Validation schema using Yup
const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
  captcha: Yup.string().required('Please complete the CAPTCHA')
});

const ContactForm = ({ email = 'mypls@mypls.com', onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      message: '',
      captcha: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      if (!captchaValue) {
        actions.setFieldError('captcha', 'Please complete the CAPTCHA');
        return;
      }

      const { name: fromName, email: fromEmail, subject, message } = values;
      setIsSubmitting(true);
      try {
        await Emails.sendEmail(
          {
            from: {
              name: `Contact Form - ${fromName}`,
              email: 'mypls@mypls.com'
            },
            replyTo: { name: fromName, email: fromEmail },
            to: [{ name: 'MyPLS', email }],
            subject,
            message,
            captcha: captchaValue
          },
          'Thank you! A PLS representative will contact you shortly',
          onClose
        );

        actions.resetForm();
        setCaptchaValue(null); // Reset CAPTCHA
      } catch (e) {
        console.error(`Error sending email: ${e.message}`);
        showToast(
          'Error sending email. Please try again, or email mypls@mypls.com directly',
          'error'
        );
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  const handleCaptchaChange = value => {
    setCaptchaValue(value);
    formik.setFieldValue('captcha', value);
  };

  return (
    <div className="fixed inset-0 flex items-center w-full justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg w-full transform transition-all duration-500 ease-in-out">
        <div className="flex w-full md:pb-1">
          <img width={160} height={160} src="/img/logoimage.png" alt="Logo" />
        </div>
        <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
        <p className="text-gray-700 mb-2">Pennsylvania Legislative Services</p>
        <p className="text-gray-700 mb-2">
          240 North Third Street, 8th Floor
          <br />
          Harrisburg, PA 17101
        </p>
        <p className="text-gray-700 mb-6">717-236-6984</p>

        <form onSubmit={formik.handleSubmit}>
          {/* Name Field */}
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Your name
          </label>
          <input
            type="text"
            name="name"
            className={`block w-full p-2 mb-1 border ${
              formik.touched.name && formik.errors.name
                ? 'border-red-500'
                : 'border-gray-400'
            } outline-none rounded focus:border-purple1`}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.name && formik.errors.name ? (
            <p className="text-red-500 text-sm mb-4">{formik.errors.name}</p>
          ) : null}

          {/* Email Field */}
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Your email
          </label>
          <input
            type="email"
            name="email"
            className={`block w-full p-2 mb-1 border ${
              formik.touched.email && formik.errors.email
                ? 'border-red-500'
                : 'border-gray-400'
            } outline-none rounded focus:border-purple1`}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <p className="text-red-500 text-sm mb-4">{formik.errors.email}</p>
          ) : null}

          {/* Subject Field */}
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Subject
          </label>
          <input
            type="text"
            name="subject"
            className={`block w-full p-2 mb-1 border ${
              formik.touched.subject && formik.errors.subject
                ? 'border-red-500'
                : 'border-gray-400'
            } outline-none rounded focus:border-purple1`}
            value={formik.values.subject}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.subject && formik.errors.subject ? (
            <p className="text-red-500 text-sm mb-4">{formik.errors.subject}</p>
          ) : null}

          {/* Message Field (Optional) */}
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Your message (optional)
          </label>
          <textarea
            name="message"
            className="block w-full p-2 mb-4 border border-gray-400 outline-none rounded h-32 focus:border-purple1"
            value={formik.values.message}
            onChange={formik.handleChange}
          />

          {/* CAPTCHA */}
          <div className="mb-2">
            <ReCaptcha onChange={handleCaptchaChange} />
            {formik.touched.captcha && formik.errors.captcha ? (
              <p className="text-red-500 text-sm mt-2">
                {formik.errors.captcha}
              </p>
            ) : null}
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="
              px-4 
              py-2 
              bg-purple1 
              text-white 
              rounded 
              transition 
              hover:bg-purpleLight 
              disabled:bg-gray-400 
              disabled:text-gray-700 
              disabled:cursor-not-allowed
            "
              disabled={!captchaValue || isSubmitting} // Disable button until CAPTCHA is completed
            >
              {isSubmitting ? 'Sending...' : 'Send'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
