import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  create_seminars_registrations,
  get_seminars
} from '../../../../../services/services';
import * as Emails from '../../../../../services/email';
import { ReCaptcha } from '../../../../../components/ReCaptcha/ReCaptcha';

import { SeminarRegistrationEmail } from './SeminarRegistrationEmail';

const seminarRegistrationSchema = Yup.object({
  seminar: Yup.string().required('The field is required.'),
  attendees: Yup.string().required('The field is required.'),
  contactName: Yup.string()
    .required('The field is required.')
    .max(400, 'The field is too long.'),
  contactEmail: Yup.string()
    .required('The field is required.')
    .email('The e-mail address entered is invalid.')
    .max(400, 'The field is too long.'),
  contactPhone: Yup.string()
    .required('The field is required.')
    .matches(/^\+?[1-9]\d{1,14}$/, 'The telephone number is invalid.')
    .max(400, 'The field is too long.'),
  captcha: Yup.string().required('Please complete the CAPTCHA')
});

const SeminarRegistration = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [seminarTypes, setSeminarTypes] = useState([]);
  const [captchaValue, setCaptchaValue] = useState(null);

  const formik = useFormik({
    initialValues: {
      seminar: '',
      attendees: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      captcha: ''
    },
    validationSchema: seminarRegistrationSchema,
    onSubmit: async (values, actions) => {
      setIsSubmitting(true);
      const success = await create_seminars_registrations({
        seminar: values.seminar,
        attendees: values.attendees,
        contactName: values.contactName,
        contactEmail: values.contactEmail,
        contactPhone: values.contactPhone
      });

      if (!success) return;

      const seminar = seminarTypes?.find(({ _id }) => _id === values.seminar);

      await Emails.sendEmail(
        {
          from: {
            name: `Seminar Registration Form - ${values.contactName}`,
            email: 'mypls@mypls.com'
          },
          replyTo: { name: values.contactName, email: values.contactEmail },
          to: [{ name: 'MyPLS', email: 'sales@mypls.com' }],
          subject: 'Seminar Registration',
          html: ReactDOMServer.renderToStaticMarkup(
            <SeminarRegistrationEmail seminar={seminar} values={values} />
          ),
          captcha: captchaValue
        },
        'Confirmation Email is sent'
      );
      actions.resetForm();
      setCaptchaValue(null);
      setIsSubmitting(false);
    }
  });

  const handleCaptchaChange = value => {
    setCaptchaValue(value);
    formik.setFieldValue('captcha', value);
  };

  const fetch_types = async () => {
    const res = await get_seminars();
    setSeminarTypes(res);
  };

  useEffect(() => {
    fetch_types();
  }, []);

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-6">
      <div className="w-full max-w-2xl bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl  mb-6 text-center font-medium font-playfair text-quoteText">
          Seminar Registration
        </h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="seminar">
              Select Seminar (required)
            </label>
            <select
              id="seminar"
              name="seminar"
              className="w-full p-3 border focus:outline-none border-gray-400 rounded focus:border-purple1 transition-colors"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.seminar}
            >
              <option value="" label="Select seminar type" />
              {seminarTypes?.map(seminar => (
                <option
                  key={seminar?.seminarType.title}
                  value={seminar._id}
                  className="flex justify-between"
                >
                  <p>
                    {seminar?.seminarType.title}--------------
                    {new Date(seminar.scheduled).toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true
                    })}
                  </p>
                </option>
              ))}
            </select>
            {formik.touched.seminar && formik.errors.seminar ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.seminar}
              </div>
            ) : null}
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium mb-2"
              htmlFor="attendees"
            >
              Attendees Names (required) please enter name with comma
              separation.
            </label>
            <input
              type="text"
              id="attendees"
              name="attendees"
              className="w-full p-3 border focus:outline-none border-gray-400 rounded focus:border-purple1 transition-colors"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.attendees}
              required
            />
            {formik.touched.attendees && formik.errors.attendees ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.attendees}
              </div>
            ) : null}
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium mb-2"
              htmlFor="contactName"
            >
              Contact Name (required)
            </label>
            <input
              type="text"
              id="contactName"
              name="contactName"
              className="w-full p-3 border focus:outline-none border-gray-400 rounded focus:border-purple1 transition-colors"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contactName}
              required
            />
            {formik.touched.contactName && formik.errors.contactName ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.contactName}
              </div>
            ) : null}
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium mb-2"
              htmlFor="contactEmail"
            >
              Contact Email (required)
            </label>
            <input
              type="email"
              id="contactEmail"
              name="contactEmail"
              className="w-full p-3 border focus:outline-none border-gray-400 rounded focus:border-purple1 transition-colors"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contactEmail}
              required
            />
            {formik.touched.contactEmail && formik.errors.contactEmail ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.contactEmail}
              </div>
            ) : null}
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium mb-2"
              htmlFor="contactPhone"
            >
              Contact Phone (required)
            </label>
            <input
              type="tel"
              id="contactPhone"
              name="contactPhone"
              className="w-full p-3 border focus:outline-none border-gray-400 rounded focus:border-purple1 transition-colors"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contactPhone}
              required
            />
            {formik.touched.contactPhone && formik.errors.contactPhone ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.contactPhone}
              </div>
            ) : null}
          </div>

          {/* CAPTCHA */}
          <div className="mb-2">
            <ReCaptcha onChange={handleCaptchaChange} />
            {formik.touched.captcha && formik.errors.captcha ? (
              <p className="text-red-500 text-sm mt-2">
                {formik.errors.captcha}
              </p>
            ) : null}
          </div>

          <div className="flex justify-center">
            <button
              disabled={isSubmitting || !captchaValue}
              type="submit"
              className="px-4 py-2 bg-purple1 text-white rounded hover:bg-purpleLight transition"
            >
              {isSubmitting ? 'Sending...' : 'Send'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SeminarRegistration;
