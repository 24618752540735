import React, { useEffect } from 'react';
import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';
import AnnouncementTable from '../../components/Announcements/AnnouncementTable';
import { useNavigate } from 'react-router-dom';

const Announcements = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if token exists in localStorage
    if (!localStorage.getItem('authToken')) {
      navigate('/home'); // Redirect to home page
    }
  }, [navigate]);
  return (
    <DashboardLayout>
      <AnnouncementTable />
    </DashboardLayout>
  );
};

export default Announcements;
