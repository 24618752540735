import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const JobFormModal = ({ closeModal, onSubmit, initialValues }) => {
  const JobSchema = Yup.object().shape({
    jobTitle: Yup.string().required('Job title is required'),
    location: Yup.string().required('Job location is required'),
    description: Yup.string().required('Job description is required'),
    recipientEmail: Yup.string().email().required('Job description is required')
  });

  const defaultValues = {
    jobTitle: '',
    location: '',
    description: '',
    recipientEmail: '',
    ...initialValues
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4">
          {initialValues ? 'Update Job' : 'Create Job'}
        </h2>

        <Formik
          initialValues={defaultValues}
          validationSchema={JobSchema}
          onSubmit={values => {
            onSubmit(values);
            closeModal();
          }}
        >
          {() => (
            <Form>
              <div className="mb-4">
                <label className="block mb-1 text-sm font-medium">
                  Job Title
                </label>
                <Field
                  name="jobTitle"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-300 outline-none"
                />
                <ErrorMessage
                  name="jobTitle"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="mb-4">
                <label className="block mb-1 text-sm font-medium">
                  Job Location
                </label>
                <Field
                  name="location"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-300 outline-none"
                />
                <ErrorMessage
                  name="location"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="mb-4">
                <label className="block mb-1 text-sm font-medium">
                  Job Description
                </label>
                <Field
                  name="description"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-300 outline-none"
                  as="textarea"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1 text-sm font-medium">
                  Recipient Email
                </label>
                <Field
                  name="recipientEmail"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-300 outline-none"
                  as="textarea"
                />
                <ErrorMessage
                  name="recipientEmail"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500 transition"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                >
                  {initialValues ? 'Update' : 'Create'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default JobFormModal;
