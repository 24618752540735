import React, { useEffect, useState } from 'react';
import SeminarTypeFormModal from './SeminarTypeFormModal';
import {
  create_seminars_types,
  delete_seminars_types,
  get_seminars_types,
  update_seminars_types
} from '../../../../services/services';
import { Delete, Edit } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

const SeminarTypeTable = () => {
  const [seminarTypes, setSeminarTypes] = useState([]);
  const [selectedSeminarType, setSelectedSeminarType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Loader state

  const openModal = (seminarType = null) => {
    setSelectedSeminarType(seminarType);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSeminarType(null);
  };

  const handleCreate = newSeminarType => {
    create_seminars_types(newSeminarType, fetch_types);
  };

  const handleUpdate = updatedSeminarType => {
    const data = {
      title: updatedSeminarType.title,
      description: updatedSeminarType.description
    };
    update_seminars_types(data, updatedSeminarType._id, fetch_types);
  };

  const fetch_types = async () => {
    setLoading(true); // Set loading to true before fetching data
    const res = await get_seminars_types();
    setSeminarTypes(res);
    setLoading(false); // Set loading to false after data is fetched
  };

  const handleDelete = id => {
    delete_seminars_types(id, fetch_types);
  };

  useEffect(() => {
    fetch_types();
  }, []);

  return (
    <div className="container mx-auto p-4">
      {loading ? (
        // Loader centered in the screen
        <div className="flex justify-center items-center h-screen">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="flex justify-end mb-4">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
              onClick={() => openModal()}
            >
              Create Seminar Type
            </button>
          </div>

          <table className="table-auto w-full text-left border">
            <thead className="bg-gray-200">
              <tr>
                <th className="p-2 border">Title</th>
                <th className="p-2 border">Description</th>
                <th className="p-2 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {seminarTypes.map(type => (
                <tr key={type._id} className="border-t">
                  <td className="p-2">{type.title}</td>
                  <td className="p-2">{type.description}</td>
                  <td className="p-2 flex space-x-2">
                    <button
                      className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600 transition"
                      onClick={() => openModal(type)}
                    >
                      <Edit />
                    </button>
                    <button
                      className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 transition"
                      onClick={() => handleDelete(type._id)}
                    >
                      <Delete />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {isModalOpen && (
            <SeminarTypeFormModal
              closeModal={closeModal}
              onSubmit={selectedSeminarType ? handleUpdate : handleCreate}
              initialValues={selectedSeminarType}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SeminarTypeTable;
