import axios from 'axios';

import { TOKEN_KEY } from '../constants/constants';

import { HOST } from './hosts';

const getAxiosInstance = async () => {
  const token = localStorage.getItem(TOKEN_KEY);
  return axios.create({
    baseURL: `${HOST}`,
    headers: {
      'Content-Type': 'application/json',
      authorization: token ? `Bearer ${token}` : null
    }
  });
};

export { getAxiosInstance };
