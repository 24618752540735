import { Home } from '@mui/icons-material';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

const SeminarSidebar = () => {
  const navigate = useNavigate();
  return (
    <div className="h-full bg-purple2 text-white w-64 flex flex-col justify-between">
      {/* Top section */}
      <div>
        <div className="p-6 text-xl font-bold border-b border-gray-700">
          Admin MyPLS
        </div>
        <nav className="p-4">
          <ul className="space-y-4">
            <li>
              <NavLink
                to="/seminars"
                className={({ isActive }) =>
                  `w-full flex flex-1 justify-start text-left py-2 px-4 hover:bg-purpleLight items-center text-white gap-2 rounded-lg ${
                    isActive
                      ? 'bg-purpleLight text-gray-300 rounded-lg'
                      : 'text-gray-400'
                  }`
                }
              >
                Seminars
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/seminar-types"
                className={({ isActive }) =>
                  `w-full flex flex-1 justify-start text-left py-2 px-4 hover:bg-purpleLight items-center text-white gap-2 rounded-lg ${
                    isActive
                      ? 'bg-purpleLight text-gray-300 rounded-lg'
                      : 'text-gray-400'
                  }`
                }
              >
                Seminar Types
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/job-openings"
                className={({ isActive }) =>
                  `w-full flex flex-1 justify-start text-left py-2 px-4 hover:bg-purpleLight items-center text-white gap-2 rounded-lg ${
                    isActive
                      ? 'bg-purpleLight text-gray-300 rounded-lg'
                      : 'text-gray-400'
                  }`
                }
              >
                Job Openings
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/announcements"
                className={({ isActive }) =>
                  `w-full flex flex-1 justify-start text-left py-2 px-4 hover:bg-purpleLight items-center text-white gap-2 rounded-lg ${
                    isActive
                      ? 'bg-purpleLight text-gray-300 rounded-lg'
                      : 'text-gray-400'
                  }`
                }
              >
                Announcements
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>

      {/* Bottom button */}
      <div className="">
        <button
          className=" w-full text-lg text-center py-4 px-4 bg-purple3 text-white flex gap-2 justify-center"
          onClick={() => {
            navigate('/');
          }}
        >
          <Home /> Return to MyPLS.com
        </button>
      </div>
    </div>
  );
};

export default SeminarSidebar;
