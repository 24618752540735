import React, { useState, useEffect } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import AnnouncementFormModal from './AnnouncementFormModal';
import {
  create_announcements,
  delete_announcements,
  get_announcements,
  update_announcement
} from '../../../../services/services';

const AnnouncementTable = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [selectedAnnouncements, setSelectedAnnouncements] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (bio = null) => {
    setSelectedAnnouncements(bio);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAnnouncements(null);
  };

  const handleCreate = newData => {
    create_announcements(newData, fetchAnnouncements);
  };

  const handleUpdate = updatedData => {
    update_announcement(
      updatedData,
      selectedAnnouncements?._id,
      fetchAnnouncements
    );
  };

  const handleDelete = id => {
    delete_announcements(id, fetchAnnouncements);
  };

  const fetchAnnouncements = async () => {
    const res = await get_announcements();
    setAnnouncements(res);
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-end mb-4">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
          onClick={() => openModal()}
        >
          Create New Announcement
        </button>
      </div>

      <table className="table-auto w-full text-left border">
        <thead className="bg-gray-200">
          <tr>
            <th className="p-2 border">Heading</th>
            <th className="p-2 border">Description</th>
            <th className="p-2 border">Active Status</th>
            <th className="p-2 border">Actions</th>
          </tr>
        </thead>
        <tbody>
          {announcements.map(announcement => (
            <tr key={announcement._id} className="border-t">
              <td className="p-2">{announcement.heading}</td>
              <td
                className="p-2"
                style={{ listStyle: 'disc' }}
                dangerouslySetInnerHTML={{ __html: announcement.description }}
              />
              <td className="p-2">
                {/* Conditional rendering based on isActive */}
                {announcement.isActive ? (
                  <span className="text-green-500 font-semibold">Active</span>
                ) : (
                  <span className="text-red-500 font-semibold">Inactive</span>
                )}
              </td>
              <td className="p-2 flex space-x-2">
                <button
                  className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600 transition"
                  onClick={() => openModal(announcement)}
                >
                  <Edit />
                </button>
                <button
                  className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 transition"
                  onClick={() => handleDelete(announcement._id)}
                >
                  <Delete />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <AnnouncementFormModal
          closeModal={closeModal}
          onSubmit={selectedAnnouncements ? handleUpdate : handleCreate}
          initialValues={selectedAnnouncements}
        />
      )}
    </div>
  );
};

export default AnnouncementTable;
