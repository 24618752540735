import React from 'react';

export function SeminarRegistrationEmail({
  seminar,
  values: { contactName, contactEmail, contactPhone, attendees }
}) {
  const formattedDate = seminar?.scheduled
    ? new Date(seminar.scheduled).toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    : '';

  return (
    <html>
      <body>
        <h2>Seminar Registration</h2>
        <p>{seminar?.seminarType?.title}</p>
        <table>
          <tbody>
            <tr>
              <td>Contact Name: </td>
              <td>{contactName}</td>
            </tr>
            <tr>
              <td>Contact Email: </td>
              <td>
                <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
              </td>
            </tr>
            <tr>
              <td>Contact Phone: </td>
              <td>
                <a href={`tel:1-${contactPhone}`}>{contactPhone}</a>
              </td>
            </tr>
            <tr>
              <td>Attendees: </td>
              <td>{attendees}</td>
            </tr>
            <tr>
              <td>Seminar: </td>
              <td>{seminar?.seminarType?.title}</td>
            </tr>
            <tr>
              <td>Date and Time: </td>
              <td>{formattedDate}</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <p>
          Tech Problem? <br />
          <b>
            <a href="mailto:teamTech@mypls.com?subject=Issue: Seminar Form">
              Contact Team Tech
            </a>
          </b>
        </p>
      </body>
    </html>
  );
}
