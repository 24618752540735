import React from 'react';
import { communication_Data } from '../../constants/ServicesContent';
import GovTracContent from '../../components/ServicesContentDisplay/PlsGovTracContent';
import { PAGov2Go_Data } from '../../constants/ServicesContent';
import { useParams } from 'react-router-dom';
import ServicesContentDisplay from '../../components/ServicesContentDisplay/ServicesContentDisplay';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const ServicesContent = () => {
  const { serviceName } = useParams();

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* Header */}
      <Header />

      {/* Main Content */}
      <div className="flex-grow p-4">
        {serviceName === 'communication-connection' && (
          <ServicesContentDisplay
            key={'communication-connection'}
            title={communication_Data.title}
            subtitle={communication_Data.subtitle}
            description={communication_Data.description}
            items={communication_Data.items}
            footer={communication_Data.footer}
          />
        )}
        {serviceName === 'pagov2go' && (
          <ServicesContentDisplay
            key={'pagov2go'}
            title={PAGov2Go_Data.title}
            subtitle={PAGov2Go_Data.subtitle}
            description={PAGov2Go_Data.description}
            items={PAGov2Go_Data.items}
            footer={PAGov2Go_Data.footer}
          />
        )}
        {serviceName === 'plsgovtrac' && <GovTracContent />}
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ServicesContent;
