import axios from 'axios';

import { showToast } from '../context/toastContext';

const createHash = async (content, nonce) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(`${content}${nonce}`);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
};

export const generateProofOfWork = async content => {
  // NOTE: It is CRITICAL this matches the difficulty in pls-server
  const PROOF_OF_WORK_DIFFICULTY = 4;

  let nonce = 0;
  let hash = '';

  while (!hash.startsWith('0'.repeat(PROOF_OF_WORK_DIFFICULTY))) {
    nonce++;
    hash = await createHash(content, nonce);
  }

  return { nonce, hash };
};

export const sendEmail = async (emailData, successMessage, onClose) => {
  const API_URL = `${process.env.REACT_APP_API_URL}/email/send`;

  const config = { headers: { 'Content-Type': 'application/json' } };

  const { from, to, replyTo, subject, message, html, captcha } = emailData;

  const data = {
    from,
    to,
    replyTo,
    subject,
    html: html || `<pre>${message}</pre>`,
    captcha
  };

  const { nonce, hash } = await generateProofOfWork(data.html);
  data.nonce = nonce;
  data.hash = hash;

  const response = await axios.post(API_URL, data, config);
  if (response.status !== 201)
    throw new Error(
      `Error sending email: ${response.status} ${response.statusText}`
    );
  successMessage && showToast(successMessage, 'success');
  onClose && onClose();
};
